import { useState, useEffect } from "react";
import React from 'react'
import './createArt.css';
import { Card, Button } from "react-bootstrap";
import api from "../../service/api";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ColorPicker from 'react-pick-color';
import { AiFillLeftCircle } from "react-icons/ai"
import ultraLogo from '../../image/ultragaz-logo-0.png'
import liguiLogo from '../../image/liquigas-logo-00.png'
import copaLogo from '../../image/copagaz-logo-0.png'
import nacionalLogo from '../../image/nacional-gas-4096.png'
import superLogo from '../../image/supergasbras-logo-0.png'
import consiLogo from '../../image/consigaz.png'
import servLogo from '../../image/SERVGAS-DISTRIBUIDORA-DE-GAS-SA.webp'
import indeLogo from '../../image/revendaindependente.png'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";

const CreateArt = () => {

    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [categorySelected, setCategorySelected] = useState();
    const typeCollaborator = sessionStorage.getItem("typeCollaborator");
    const [imageSelected, setImageSelected] = useState(ultragraz)
    const [marcaSelected, setMarcaSelected] = useState('ultragaz');
    const [ultragraz, setUltragraz] = useState('');
    const [consigaz, setConsigaz] = useState('')
    const [copagaz, setCopagaz] = useState('')
    const [independente, setIndependente] = useState('')
    const [liquigas, setLiquigas] = useState('')
    const [nacionalGas, setNacionalGas] = useState('')
    const [ngc, setNgc] = useState('')
    const [servigas, setServigas] = useState('')
    const [supergasbras, setSupergasbras] = useState('')
    const [ultragrazFile, setUltragrazFile] = useState('');
    const [consigazFile, setConsigazFile] = useState('')
    const [copagazFile, setCopagazFile] = useState('')
    const [independenteFile, setIndependenteFile] = useState('')
    const [liquigasFile, setLiquigasFile] = useState('')
    const [nacionalGasFile, setNacionalGasFile] = useState('')
    const [ngcFile, setNgcFile] = useState('')
    const [servigasFile, setServigasFile] = useState('')
    const [supergasbrasFile, setSupergasbrasFile] = useState('')

    useEffect(() => {
        api
            .get('/TodasCategorias')
            .then(response => {
                setCategory(response.data);
            })
    }, [])

    useEffect(() => {
        api
            .get('/TodasSubcategorias')
            .then(response => {
                setSubcategory(response.data);
            })
    }, [])

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        var arrayImageFile = []
        let urlUltra = null;
        let urlLiqui = null;
        let urlConsi = null;
        let urlCopa = null;
        let urlNgc = null;
        let urlNaci = null;
        let urlSuper = null;
        let urlServ = null;
        let urlInd = null;

        if (ultragrazFile !== '') {
            arrayImageFile.push(ultragrazFile)
            urlUltra = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + ultragrazFile.name
        }
        if (liquigasFile !== '') {
            arrayImageFile.push(liquigasFile)
            urlLiqui = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + liquigasFile.name
        }
        if (consigazFile !== '') {
            arrayImageFile.push(consigazFile)
            urlConsi = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + consigazFile.name
        }
        if (copagazFile !== '') {
            arrayImageFile.push(copagazFile)
            urlCopa = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + copagazFile.name
        }
        if (ngcFile !== '') {
            arrayImageFile.push(ngcFile)
            urlNgc = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + ngcFile.name
        }
        if (nacionalGasFile !== '') {
            arrayImageFile.push(nacionalGasFile)
            urlNaci = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + nacionalGasFile.name
        }
        if (supergasbrasFile !== '') {
            arrayImageFile.push(supergasbrasFile)
            urlSuper = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + supergasbrasFile.name
        }
        if (servigasFile !== '') {
            arrayImageFile.push(servigasFile)
            urlServ = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + servigasFile.name
        }
        if (independenteFile !== '') {
            arrayImageFile.push(independenteFile)
            urlInd = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + independenteFile.name
        }

        var dateStart = new Date(data.dateStartArt);
        var dateStartTime = dateStart.getTime();

        var dateEnd = new Date(data.dateEndArt);
        var dateEndTime = dateEnd.getTime();

        let postComplete = data.artPost.split('\n');
        var arrayPost = [];
        postComplete.map(key => {
            postComplete.map(key => {
                if (key !== '') {
                    if (key !== ' ') {
                        if (key !== '  ') {
                            arrayPost.push(key);
                        }
                    }

                }
            })
        })

        let dados = {
            'image[]': arrayImageFile,
            ultragaz_url: urlUltra,
            liquigas_url: urlLiqui,
            copagaz_url: urlCopa,
            consigaz_url: urlConsi,
            nacionalGas_url: urlNaci,
            ngc_url: urlNgc,
            servigas_url: urlServ,
            supergasbras_url: urlSuper,
            independente_url: urlInd,
            name: data.artName,
            revenda: data.artRevenda,
            category: data.artCategory,
            subcategory: data.artSubcategory,
            situation: data.artSituation,
            post: arrayPost.join(' <br /> '),
            date_start_situation: dateStartTime,
            date_end_situation: dateEndTime,
            size_image: data.artSize
        }

        api.post("/SalvarImagem", dados, headers);

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function mostraImagem(img) {
        if (img.files && img.files[0]) {
            var reader = new FileReader();
            var imagem = document.getElementById("artImage");

            reader.onload = function (e) {
                if (marcaSelected == 'ultragaz') {
                    setUltragraz(e.target.result)
                    setImageSelected(e.target.result)
                    setUltragrazFile(img.files[0])
                } else if (marcaSelected == 'liquigas') {
                    setLiquigas(e.target.result)
                    setImageSelected(e.target.result)
                    setLiquigasFile(img.files[0])
                } else if (marcaSelected == 'copagaz') {
                    setCopagaz(e.target.result)
                    setImageSelected(e.target.result)
                    setCopagazFile(img.files[0])
                } else if (marcaSelected == 'consigaz') {
                    setConsigaz(e.target.result)
                    setImageSelected(e.target.result)
                    setConsigazFile(img.files[0])
                } else if (marcaSelected == 'nacionalGas') {
                    setNacionalGas(e.target.result)
                    setImageSelected(e.target.result)
                    setNacionalGasFile(img.files[0])
                } else if (marcaSelected == 'ngc') {
                    setNgc(e.target.result)
                    setImageSelected(e.target.result)
                    setNgcFile(img.files[0])
                } else if (marcaSelected == 'servigas') {
                    setServigas(e.target.result)
                    setImageSelected(e.target.result)
                    setServigasFile(img.files[0])
                } else if (marcaSelected == 'supergasbras') {
                    setSupergasbras(e.target.result)
                    setImageSelected(e.target.result)
                    setSupergasbrasFile(img.files[0])
                } else if (marcaSelected == 'independente') {
                    setIndependente(e.target.result)
                    setImageSelected(e.target.result)
                    setIndependenteFile(img.files[0])
                }

            };

            reader.readAsDataURL(img.files[0]);
        }
    }

    function dateSituation(e) {
        if (e == 'emAlta') {
            document.getElementById("datesSituation").style.display = 'flex'
        } else {
            document.getElementById("datesSituation").style.display = 'none'
            document.getElementById("dateStartArt").value = ''
            document.getElementById("dateEndArt").value = ''

        }
    }

    return (
        <>
            <Loading />
            <div className='orderedContainer'>
                <div className="titleAndButton">
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Link to="/artes" style={{ background: 'transparent', color: 'black', border: 'none', textDecoration: 'none' }}><AiFillLeftCircle style={{ fontSize: 26 }} /> Voltar </Link>
                        <h3 style={{marginLeft: 10}}>Cadastrar arte</h3>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', height: '135vh', borderRadius: '10px', padding: '10px' }}>
                    <form className="input" onSubmit={submitForm} enctype="multipart/form-data" >
                        <div className="col2">
                            <div className="divInputsImages">
                                <img src={imageSelected} className="imgInput" id='artImage' />
                                <input type="file" name="artImage" style={{ marginTop: 5 }} onChange={(e) => {
                                    mostraImagem(e.target)
                                }} />
                                <div className="containerSelectedMarca">
                                    <div
                                        className="divSelectedMarca"
                                        onClick={e => {
                                            setImageSelected(ultragraz)
                                            setMarcaSelected('ultragaz')
                                        }}
                                        style={marcaSelected == 'ultragaz' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}
                                    >
                                        <img src={ultraLogo} className="imgMarcaLogo" />
                                    </div>
                                    <div className="divSelectedMarca" onClick={e => {
                                        setImageSelected(liquigas)
                                        setMarcaSelected('liquigas')
                                    }} style={marcaSelected == 'liquigas' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={liguiLogo} className="imgMarcaLogo" /></div>
                                    <div className="divSelectedMarca" onClick={e => {
                                        setImageSelected(copagaz)
                                        setMarcaSelected('copagaz')
                                    }} style={marcaSelected == 'copagaz' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={copaLogo} className="imgMarcaLogo" /></div>
                                    <div className="divSelectedMarca" onClick={e => {
                                        setImageSelected(consigaz)
                                        setMarcaSelected('consigaz')
                                    }} style={marcaSelected == 'consigaz' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={consiLogo} className="imgMarcaLogo" /></div>
                                    <div className="divSelectedMarca" onClick={e => {
                                        setImageSelected(nacionalGas)
                                        setMarcaSelected('nacionalGas')
                                    }} style={marcaSelected == 'nacionalGas' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={nacionalLogo} className="imgMarcaLogo" /></div>
                                    <div className="divSelectedMarca" onClick={e => {
                                        setImageSelected(ngc)
                                        setMarcaSelected('ngc')
                                    }} style={marcaSelected == 'ngc' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={nacionalLogo} className="imgMarcaLogo" /> NGC</div>
                                    <div className="divSelectedMarca" onClick={e => {
                                        setImageSelected(servigas)
                                        setMarcaSelected('servigas')
                                    }} style={marcaSelected == 'servigas' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={servLogo} className="imgMarcaLogo" /></div>
                                    <div className="divSelectedMarca" onClick={e => {
                                        setImageSelected(supergasbras)
                                        setMarcaSelected('supergasbras')
                                    }} style={marcaSelected == 'supergasbras' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={superLogo} className="imgMarcaLogo" /></div>
                                    <div className="divSelectedMarca" onClick={e => {
                                        setImageSelected(independente)
                                        setMarcaSelected('independente')
                                    }} style={marcaSelected == 'independente' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={indeLogo} className="imgMarcaLogo" /></div>
                                </div>

                            </div>
                            <div className="divDetaisImage">
                                <TextField id="outlined-basic" label="Nome da Imagem" variant="outlined" className="inputDetails" name='artName' />
                                <FormControl className="inputDetails">
                                    <InputLabel id="demo-simple-select-label">Situação </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id='demo-simple-select'
                                        name='artSituation'
                                        required
                                        onChange={(e) => dateSituation(e.target.value)}
                                    >
                                        <MenuItem value='emAlta'>Em alta</MenuItem>
                                        <MenuItem value='normal'>Normal</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl className="inputDetails">
                                    <InputLabel id="demo-simple-select-label">Tamanho da imagem</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id='demo-simple-select'
                                        name='artSize'
                                        required
                                    >
                                        <MenuItem value='1080x1080'>Quadrada</MenuItem>
                                        <MenuItem value='1080x1350'>Retangular</MenuItem>
                                    </Select>
                                </FormControl>

                                <div className="container2itens" id="datesSituation" style={{ justifyContent: "space-between", display: 'none', width: '100%' }}>
                                    <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                        <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Data inicial da situação </InputLabel>
                                        <input
                                            type="date"
                                            id="dateStartArt"
                                            name="dateStartArt"
                                            className="inputCadastro"
                                        />
                                    </div>
                                    <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                        <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Data final da situação </InputLabel>
                                        <input
                                            type="date"
                                            id="dateEndArt"
                                            name="dateEndArt"
                                            className="inputCadastro"
                                        />
                                    </div>
                                </div>
                                <FormControl className="inputDetails">
                                    <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={age}
                                        label="Categoria"
                                        onChange={e => setCategorySelected(e.target.value)}
                                        name="artCategory"
                                    >
                                        {category.map(key => {
                                            return (
                                                <MenuItem value={key['id']}>{key['name']}</MenuItem>
                                            )
                                        })}

                                    </Select>
                                </FormControl>
                                <FormControl className="inputDetails">
                                    <InputLabel id="demo-simple-select-label">Subcategoria</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={age}
                                        label="Subcategoria"
                                        name="artSubcategory"
                                    // onChange={handleChange}
                                    >
                                        {subcategory.map(key => {
                                            if (key['category_id'] == categorySelected) {
                                                return (
                                                    <MenuItem value={key['id']}>{key['name']}</MenuItem>
                                                )
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                                <TextField
                                    id="outlined-multiline-static"
                                    className="inputDetails"
                                    label="Texto de legenda"
                                    multiline
                                    rows={7}
                                    name='artPost'
                                // defaultValue="Default Value"
                                />
                                <div className="buttonLogin" style={{ marginLeft: 10, marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                                    <button className="buttonSecundary" >
                                        Cadastrar arte
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </Card>
            </div >
        </>
    )
}

export default CreateArt