import { useState, useEffect } from "react";
import React from 'react'
import './editArt.css';
import { Card, Button } from "react-bootstrap";
import api from "../../service/api";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ColorPicker from 'react-pick-color';
import { AiFillLeftCircle } from "react-icons/ai"
import emojis from "../../assets/emojis";
import ultraLogo from '../../image/ultragaz-logo-0.png'
import liguiLogo from '../../image/liquigas-logo-00.png'
import copaLogo from '../../image/copagaz-logo-0.png'
import nacionalLogo from '../../image/nacional-gas-4096.png'
import superLogo from '../../image/supergasbras-logo-0.png'
import consiLogo from '../../image/consigaz.png'
import servLogo from '../../image/SERVGAS-DISTRIBUIDORA-DE-GAS-SA.webp'
import indeLogo from '../../image/revendaindependente.png'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";

const EditArt = () => {

    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [categorySelected, setCategorySelected] = useState();
    const [allArts, setAllArts] = useState([]);
    const [ultragraz, setUltragraz] = useState(null);
    const [consigaz, setConsigaz] = useState(null)
    const [copagaz, setCopagaz] = useState(null)
    const [independente, setIndependente] = useState(null)
    const [liquigas, setLiquigas] = useState(null)
    const [nacionalGas, setNacionalGas] = useState(null)
    const [ngc, setNgc] = useState(null)
    const [servigas, setServigas] = useState(null)
    const [supergasbras, setSupergasbras] = useState(null)
    const [ultragrazFile, setUltragrazFile] = useState('');
    const [consigazFile, setConsigazFile] = useState('')
    const [copagazFile, setCopagazFile] = useState('')
    const [independenteFile, setIndependenteFile] = useState('')
    const [liquigasFile, setLiquigasFile] = useState('')
    const [nacionalGasFile, setNacionalGasFile] = useState('')
    const [ngcFile, setNgcFile] = useState('')
    const [servigasFile, setServigasFile] = useState('')
    const [supergasbrasFile, setSupergasbrasFile] = useState('')
    const [imageSelected, setImageSelected] = useState(ultragraz)
    const [marcaSelected, setMarcaSelected] = useState('ultragaz');

    var arrayEmojis = Object.keys(emojis).map((key) => [key, emojis[key]]);
    const editArtId = sessionStorage.getItem('artEditId');
    const typeCollaborator = sessionStorage.getItem("typeCollaborator");
    useEffect(() => {
        api
            .get('/TodasArtes')
            .then(response => {
                setAllArts(response.data);
                response.data.map(key => {
                    if (key['id'] == editArtId) {
                        setCategorySelected(key['category'])
                        setUltragraz(key['ultragaz_url'])
                        setImageSelected(key['ultragaz_url'])
                        setConsigaz(key['consigaz_url'])
                        setCopagaz(key['copagaz_url'])
                        setIndependente(key['independente_url'])
                        setLiquigas(key['liquigas_url'])
                        setNacionalGas(key['nacionalGas_url'])
                        setNgc(key['ngc_url'])
                        setServigas(key['servigas_url'])
                        setSupergasbras(key['supergasbras_url'])
                    }
                })
            })
    }, [])

    useEffect(() => {
        api
            .get('/TodasCategorias')
            .then(response => {
                setCategory(response.data);
            })
    }, [])

    useEffect(() => {
        api
            .get('/TodasSubcategorias')
            .then(response => {
                setSubcategory(response.data);
            })
    }, [])

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }

        var arrayImageFile = []
        let ultragazImg = ultragraz
        let liquigasImg = liquigas;
        let consigazImg = consigaz
        let copagazImg = copagaz
        let ngcImg = ngc
        let nacionalImg = nacionalGas
        let supergasbrasImg = supergasbras
        let servigasImg = servigas
        let independenteImg = independente

        if (ultragrazFile !== '') {
            arrayImageFile.push(ultragrazFile)
            ultragazImg = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + ultragrazFile.name
        }
        if (liquigasFile !== '') {
            arrayImageFile.push(liquigasFile)
            liquigasImg = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + liquigasFile.name
        }
        if (consigazFile !== '') {
            arrayImageFile.push(consigazFile)
            consigazImg = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + consigazFile.name
        }
        if (copagazFile !== '') {
            arrayImageFile.push(copagazFile)
            copagazImg = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + copagazFile.name
        }
        if (ngcFile !== '') {
            arrayImageFile.push(ngcFile)
            ngcImg = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + ngcFile.name
        }
        if (nacionalGasFile !== '') {
            arrayImageFile.push(nacionalGasFile)
            nacionalImg = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + nacionalGasFile.name
        }
        if (supergasbrasFile !== '') {
            arrayImageFile.push(supergasbrasFile)
            supergasbrasImg = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + supergasbrasFile.name
        }
        if (servigasFile !== '') {
            arrayImageFile.push(servigasFile)
            servigasImg = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + servigasFile.name
        }
        if (independenteFile !== '') {
            arrayImageFile.push(independenteFile)
            independenteImg = 'https://apiadmin.clubedorevendedordegas.com.br/files/arts/' + independenteFile.name
        }

        var dateStart = new Date(data.dateStartArt);
        var dateStartTime = dateStart.getTime();

        var dateEnd = new Date(data.dateEndArt);
        var dateEndTime = dateEnd.getTime();

        let postComplete = data.artPost.split('\n');
        var arrayPost = [];
        postComplete.map(key => {
            if (key !== '') {
                if (key !== ' ') {
                    if (key !== '  ') {
                        arrayPost.push(key);
                    }
                }

            }
        })

        let dados = {
            id: editArtId,
            'image[]': arrayImageFile,
            ultragaz_url: ultragazImg,
            liquigas_url: liquigasImg,
            copagaz_url: copagazImg,
            consigaz_url: consigazImg,
            nacionalGas_url: nacionalImg,
            ngc_url: ngcImg,
            servigas_url: servigasImg,
            supergasbras_url: supergasbrasImg,
            independente_url: independenteImg,
            name: data.artName,
            revenda: data.artRevenda,
            category: data.artCategory,
            subcategory: data.artSubcategory,
            situation: data.artSituation,
            post: arrayPost.join(' <br /> '),
            date_start_situation: dateStartTime,
            date_end_situation: dateEndTime,
            size_image: data.artSize
        }

        // let dados = {
        //     id: editArtId,
        //     image: data.artImageEdit,
        //     name: data.artNameEdit,
        //     category: data.artCategoryEdit,
        //     subcategory: data.artSubcategoryEdit,
        //     situation: data.artSituationEdit,
        //     post: arrayPostEdit.join(' <br /> '),
        //     date_start_situation: dateStartTimeEdit,
        //     date_end_situation: dateEndTimeEdit
        // }

        api.put("/AtualizarArte", dados, headers);

        setOpen(true)
        setTimeout(function () {
            window.location.href = '/artes';
        }, 3000);
    }

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function mostraImagem(img) {
        if (img.files && img.files[0]) {
            var reader = new FileReader();
            var imagem = document.getElementById("artImage");

            reader.onload = function (e) {
                if (marcaSelected == 'ultragaz') {
                    setUltragraz(e.target.result)
                    setImageSelected(e.target.result)
                    setUltragrazFile(img.files[0])
                } else if (marcaSelected == 'liquigas') {
                    setLiquigas(e.target.result)
                    setImageSelected(e.target.result)
                    setLiquigasFile(img.files[0])
                } else if (marcaSelected == 'copagaz') {
                    setCopagaz(e.target.result)
                    setImageSelected(e.target.result)
                    setCopagazFile(img.files[0])
                } else if (marcaSelected == 'consigaz') {
                    setConsigaz(e.target.result)
                    setImageSelected(e.target.result)
                    setConsigazFile(img.files[0])
                } else if (marcaSelected == 'nacionalGas') {
                    setNacionalGas(e.target.result)
                    setImageSelected(e.target.result)
                    setNacionalGasFile(img.files[0])
                } else if (marcaSelected == 'ngc') {
                    setNgc(e.target.result)
                    setImageSelected(e.target.result)
                    setNgcFile(img.files[0])
                } else if (marcaSelected == 'servigas') {
                    setServigas(e.target.result)
                    setImageSelected(e.target.result)
                    setServigasFile(img.files[0])
                } else if (marcaSelected == 'supergasbras') {
                    setSupergasbras(e.target.result)
                    setImageSelected(e.target.result)
                    setSupergasbrasFile(img.files[0])
                } else if (marcaSelected == 'independente') {
                    setIndependente(e.target.result)
                    setImageSelected(e.target.result)
                    setIndependenteFile(img.files[0])
                }

            };

            reader.readAsDataURL(img.files[0]);
        }
    }

    function dateSituation(e) {
        if (e == 'emAlta') {
            document.getElementById("datesSituation").style.display = 'flex'
        } else {
            document.getElementById("datesSituation").style.display = 'none'
            document.getElementById("dateStartArt").value = ''
            document.getElementById("dateEndArt").value = ''

        }
    }

    return (
        <>
            <Loading />
            <div className='orderedContainer'>
                <div className="titleAndButton">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Link to="/artes" style={{ background: 'transparent', color: 'black', border: 'none', textDecoration: 'none' }}><AiFillLeftCircle style={{ fontSize: 26 }} /> Voltar </Link>
                        <h3 style={{ marginLeft: 10 }}>Atualizar arte</h3>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', height: '135vh', borderRadius: '10px', padding: '10px' }}>
                    <form className="input" onSubmit={submitForm} enctype="multipart/form-data" >
                        {allArts.map(key => {
                            if (key['id'] == editArtId) {
                                let dateFormatedStart = '';
                                let dateFormatedEnd = '';
                                if (key['date_start_situation'] !== null) {
                                    var dateStartFormated = new Date(Number(key['date_start_situation']));
                                    let dayStart = dateStartFormated.getDate() + 1;
                                    var monthStart = dateStartFormated.getMonth() + 1;
                                    var yearStart = dateStartFormated.getFullYear();

                                    if (dayStart > 1 && dayStart < 10) {
                                        dayStart = "0" + dayStart;
                                    }

                                    dateFormatedStart = [yearStart, monthStart, dayStart].join('-');
                                }

                                if (key['date_end_situation'] !== null) {
                                    var dateEndFormated = new Date(Number(key['date_end_situation']));
                                    let dayEnd = dateEndFormated.getDate() + 1;
                                    var monthEnd = dateEndFormated.getMonth() + 1;
                                    var yearEnd = dateEndFormated.getFullYear();

                                    if (dayEnd > 1 && dayEnd < 10) {
                                        dayEnd = "0" + dayEnd;
                                    }

                                    dateFormatedEnd = [yearEnd, monthEnd, dayEnd].join('-');
                                }

                                var linhas = key['post'].split('<br /> ');
                                return (
                                    <div className="col2">
                                        <div className="divInputsImages">
                                            <img src={imageSelected} className="imgInput" id='artImage' />
                                            <input type="file" name="artImage" style={{ marginTop: 5 }} onChange={(e) => {
                                                mostraImagem(e.target)
                                            }} />
                                            <div className="containerSelectedMarca">
                                                <div
                                                    className="divSelectedMarca"
                                                    onClick={e => {
                                                        setImageSelected(ultragraz)
                                                        setMarcaSelected('ultragaz')
                                                    }}
                                                    style={marcaSelected == 'ultragaz' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}
                                                >
                                                    <img src={ultraLogo} className="imgMarcaLogo" />
                                                </div>
                                                <div className="divSelectedMarca" onClick={e => {
                                                    setImageSelected(liquigas)
                                                    setMarcaSelected('liquigas')
                                                }} style={marcaSelected == 'liquigas' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={liguiLogo} className="imgMarcaLogo" /></div>
                                                <div className="divSelectedMarca" onClick={e => {
                                                    setImageSelected(copagaz)
                                                    setMarcaSelected('copagaz')
                                                }} style={marcaSelected == 'copagaz' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={copaLogo} className="imgMarcaLogo" /></div>
                                                <div className="divSelectedMarca" onClick={e => {
                                                    setImageSelected(consigaz)
                                                    setMarcaSelected('consigaz')
                                                }} style={marcaSelected == 'consigaz' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={consiLogo} className="imgMarcaLogo" /></div>
                                                <div className="divSelectedMarca" onClick={e => {
                                                    setImageSelected(nacionalGas)
                                                    setMarcaSelected('nacionalGas')
                                                }} style={marcaSelected == 'nacionalGas' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={nacionalLogo} className="imgMarcaLogo" /></div>
                                                <div className="divSelectedMarca" onClick={e => {
                                                    setImageSelected(ngc)
                                                    setMarcaSelected('ngc')
                                                }} style={marcaSelected == 'ngc' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={nacionalLogo} className="imgMarcaLogo" /> NGC</div>
                                                <div className="divSelectedMarca" onClick={e => {
                                                    setImageSelected(servigas)
                                                    setMarcaSelected('servigas')
                                                }} style={marcaSelected == 'servigas' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={servLogo} className="imgMarcaLogo" /></div>
                                                <div className="divSelectedMarca" onClick={e => {
                                                    setImageSelected(supergasbras)
                                                    setMarcaSelected('supergasbras')
                                                }} style={marcaSelected == 'supergasbras' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={superLogo} className="imgMarcaLogo" /></div>
                                                <div className="divSelectedMarca" onClick={e => {
                                                    setImageSelected(independente)
                                                    setMarcaSelected('independente')
                                                }} style={marcaSelected == 'independente' ? { backgroundColor: '#25368C', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' } : { backgroundColor: '#D9D9D9' }}><img src={indeLogo} className="imgMarcaLogo" /></div>
                                            </div>

                                        </div>
                                        <div className="divDetaisImage">
                                            <TextField id="outlined-basic" label="Nome da Imagem" variant="outlined" className="inputDetails" name='artName' defaultValue={key['name']} />
                                            <FormControl className="inputDetails">
                                                <InputLabel id="demo-simple-select-label">Situação </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id='demo-simple-select'
                                                    name='artSituation'
                                                    required
                                                    onChange={(e) => dateSituation(e.target.value)}
                                                    defaultValue={key['situation']}
                                                >
                                                    <MenuItem value='emAlta' >Em alta</MenuItem>
                                                    <MenuItem value='normal' >Normal</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <FormControl className="inputDetails">
                                                <InputLabel id="demo-simple-select-label">Tamanho da imagem</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id='demo-simple-select'
                                                    name='artSize'
                                                    required
                                                    defaultValue={key['size_image']}
                                                >
                                                    <MenuItem value='1080x1080'>Quadrada</MenuItem>
                                                    <MenuItem value='1080x1350'>Retangular</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <div className="container2itens" id="datesSituation" style={{ justifyContent: "space-between", display: 'none', width: '100%' }}>
                                                <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                    <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Data inicial da situação </InputLabel>
                                                    <input
                                                        type="date"
                                                        id="dateStartArt"
                                                        name="dateStartArt"
                                                        className="inputCadastro"
                                                        defaultValue={dateFormatedStart}
                                                    />
                                                </div>
                                                <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                    <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Data final da situação </InputLabel>
                                                    <input
                                                        type="date"
                                                        id="dateEndArt"
                                                        name="dateEndArt"
                                                        className="inputCadastro"
                                                        defaultValue={dateFormatedEnd}
                                                    />
                                                </div>
                                            </div>
                                            <FormControl className="inputDetails">
                                                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                                <Select
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    label="Categoria"
                                                    onChange={e => setCategorySelected(e.target.value)}
                                                    name="artCategory"
                                                    defaultValue={key['category']}
                                                >
                                                    {category.map(key => {
                                                        return (
                                                            <MenuItem value={key['id']}>{key['name']}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                            </FormControl>
                                            <FormControl className="inputDetails">
                                                <InputLabel id="demo-simple-select-label">Subcategoria</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    label="Subcategoria"
                                                    name="artSubcategory"
                                                    defaultValue={key['subcategory']}
                                                // onChange={handleChange}
                                                >
                                                    {subcategory.map(key => {
                                                        if (key['category_id'] == categorySelected) {
                                                            return (
                                                                <MenuItem value={key['id']}>{key['name']}</MenuItem>
                                                            )
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                id="outlined-multiline-static"
                                                className="inputDetails"
                                                label="Texto de legenda"
                                                multiline
                                                rows={7}
                                                name='artPost'
                                                defaultValue={linhas.join('\n \n')}
                                            />
                                            <div className="buttonLogin" style={{ marginLeft: 10, marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                                                <button className="buttonSecundary" >
                                                    Atualizar arte
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </form>
                </Card>
            </div >
            {/* <div className='orderedContainer'>
                <div className="titleAndButton">
                    <div>
                        <Button href="/artes" style={{ background: 'transparent', color: 'black', border: 'none', textDecoration: 'none' }}><AiFillLeftCircle style={{ fontSize: 26 }} /> Voltar</Button>
                    </div>
                </div>
                <div className="titleAndButton">
                    <div>
                        <h3>Editar arte</h3>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', height: '135vh', borderRadius: '10px', padding: '10px' }}>
                    <form className="input" onSubmit={submitForm}>
                        {allArts.map(key => {
                            if (key['id'] == editArtId) {
                                let dateFormatedStart = '';
                                let dateFormatedEnd = '';
                                if (key['date_start_situation'] !== null) {
                                    var dateStartFormated = new Date(Number(key['date_start_situation']));
                                    let dayStart = dateStartFormated.getDate() + 1;
                                    var monthStart = dateStartFormated.getMonth() + 1;
                                    var yearStart = dateStartFormated.getFullYear();

                                    if (dayStart > 1 && dayStart < 10) {
                                        dayStart = "0" + dayStart;
                                    }

                                    dateFormatedStart = [yearStart, monthStart, dayStart].join('-');
                                }

                                if (key['date_end_situation'] !== null) {
                                    var dateEndFormated = new Date(Number(key['date_end_situation']));
                                    let dayEnd = dateEndFormated.getDate() + 1;
                                    var monthEnd = dateEndFormated.getMonth() + 1;
                                    var yearEnd = dateEndFormated.getFullYear();

                                    if (dayEnd > 1 && dayEnd < 10) {
                                        dayEnd = "0" + dayEnd;
                                    }

                                    dateFormatedEnd = [yearEnd, monthEnd, dayEnd].join('-');
                                }

                                var linhas = key['post'].split('<br /> ');
                                return (
                                    <>
                                        <div className="container2itens" style={{ justifyContent: "center" }}>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Nome da imagem </InputLabel>
                                                <input
                                                    required
                                                    id="artNameEdit"
                                                    name="artNameEdit"
                                                    className="inputCadastro"
                                                    defaultValue={key['name']}
                                                />
                                            </div>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Situação </InputLabel>
                                                <Form.Select
                                                    id='artSituationEdit'
                                                    name='artSituationEdit'
                                                    className="inputCadastro"
                                                    required
                                                    onChange={(e) => dateSituation(e.target.value)}
                                                >
                                                    <option value=''>Selecione uma situação </option>
                                                    <option value='emAlta' selected={key['situation'] == 'emAlta'}>Em alta</option>
                                                    <option value='normal' selected={key['situation'] == 'normal'}>Normal</option>
                                                </Form.Select>
                                            </div>
                                        </div>

                                        <div className="container2itens" id="datesSituation" style={{ justifyContent: "center", display: key['situation'] == 'emAlta' ? 'flex' : 'none' }}>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Data inicial da situação </InputLabel>
                                                <input
                                                    type="date"
                                                    id="dateStartArtEdit"
                                                    name="dateStartArtEdit"
                                                    className="inputCadastro"
                                                    defaultValue={dateFormatedStart}
                                                />
                                            </div>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Data final da situação </InputLabel>
                                                <input
                                                    type="date"
                                                    id="dateEndArtEdit"
                                                    name="dateEndArtEdit"
                                                    className="inputCadastro"
                                                    defaultValue={dateFormatedEnd}
                                                />
                                            </div>
                                        </div>

                                        <div className="container2itens" style={{ justifyContent: "center", paddingTop: 10 }}>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Categoria </InputLabel>
                                                <Form.Select
                                                    id='typeCollaborator'
                                                    name='artCategoryEdit'
                                                    className="inputCadastro"
                                                    required
                                                    onChange={(e) => setCategorySelected(e.target.value)}
                                                >
                                                    <option value=''>Selecione uma categoria</option>
                                                    {category.map(c => {
                                                        return <option selected={key['category'] == c['id']} value={c['id']}>{c['name']}</option>
                                                    })}
                                                </Form.Select>
                                            </div>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Subcategoria</InputLabel>
                                                <Form.Select
                                                    id='typeCollaborator'
                                                    name='artSubcategoryEdit'
                                                    className="inputCadastro"
                                                >
                                                    <option value=''>Selecione um Subcategoria</option>
                                                    {subcategory.map(s => {
                                                        if (s['category_id'] == categorySelected) {
                                                            return <option selected={key['subcategory'] == s['id']} value={s['id']}>{s['name']}</option>
                                                        }
                                                    })}
                                                </Form.Select>
                                            </div>
                                        </div>

                                        <div className="container2itens" style={{ justifyContent: "center", paddingTop: 10 }}>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10 }}>Arte</InputLabel>
                                                <img src={key['url']} className="imgProfile" id="artImage" />
                                                <input
                                                    type="file"
                                                    name="artImageEdit"
                                                    style={{ marginLeft: 10 }}
                                                    onChange={(e) => {
                                                        mostraImagem(e.target)
                                                    }}
                                                />
                                            </div>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10 }}>Texto para postagem</InputLabel>
                                                <textarea
                                                    name="artPostEdit"
                                                    rows={14}
                                                    style={{ padding: 10, resize: 'none' }}
                                                    defaultValue={linhas.join('\n \n')}
                                                />
                                                <Form.Select
                                                    className="inputCadastro"
                                                >
                                                    {arrayEmojis.map(key => {
                                                        return (
                                                            <option value={key[1]['emoji']}>{key[1]['emoji']}</option>
                                                        )

                                                    })}
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="buttonLogin" style={{ marginLeft: 10, marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                                            <button className="buttonSecundary" >
                                                Editar arte
                                            </button>
                                        </div>
                                    </>
                                )
                            }

                        })}

                    </form>
                </Card>
            </div > */}
        </>
    )
}

export default EditArt